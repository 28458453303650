// module
import React from 'react';
import Marquee from 'react-fast-marquee';
import { Box, Typography } from '@mui/material';
// style

import classes from './Ticker.module.scss';


const Ticker = (props) => {
  const { children = 'КУРСЫ' } = props;

  const marquee = (direction = 'left') => {
    return (
      <Marquee speed={20} className={classes.ticker} direction={direction} gradientWidth={0}>
        {new Array(27).fill(null).map((_, i) => <Typography className={classes.text} key={i}>{children}</Typography>)}
      </Marquee>
    );
  };

  return (
    <Box>
      {marquee('right')}
      {marquee()}
    </Box>
  );
};

export default Ticker;
