import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'course';

const ENDPOINTS = { course: '/api/v1/courses/' };

export const getCourseBySlug = createAsyncThunk(
  `${name}/getCourseBySlug`,
  async (slug, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.course}${slug}/`);

    return response.data;
  },
);

export const getCourseList = createAsyncThunk(
  `${name}/getCourseList`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.course}`);

    return response.data;
  },
);

const initialState = {
  courseListStatus: REQUEST_STATUSES.NOT_REQUESTED,
  courseList: [],
  courseListError: null,
  courseStatus: REQUEST_STATUSES.NOT_REQUESTED,
  course: {},
  courseError: null,
};

const courseSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getCourseList, {
      status: 'courseStatus',
      data: 'courseList',
      error: 'courseError',
      options: { concat: false },
    });
    addQueryCases(builder, getCourseBySlug, {
      status: 'courseStatus',
      data: 'course',
      error: 'courseError',
      options: { concat: false },
    });
  },
});

export default courseSlice;

export const detailCourseSelect = (state) => state[ name ];
