import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'allcourses';

const ENDPOINTS = { allcourses: '/api/v1/courses/' };

export const getAllCourses = createAsyncThunk(
  `${name}/getAllCourses`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.allcourses}`);
    return response.data;
  }
);

const initialState = {
  allcoursesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  allcourses: [],
  allcoursesError: null
};

const allcoursesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getAllCourses, {
      status: 'allcoursesStatus',
      data: 'allcourses',
      error: 'allcoursesError',
      options: { concat: false }
    });
  }
});

export default allcoursesSlice;
