import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'companies';

const ENDPOINTS = { reviews: '/api/v1/companies/' };

export const getCompanies = createAsyncThunk(
  `${name}/getCompanies`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.reviews}`);

    return response.data;
  },
);

const initialState = {
  companiesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  companies: {},
  companiesError: null,
};

const companiesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getCompanies, {
      status: 'companiesStatus',
      data: 'companies',
      error: 'companiesError',
      options: { concat: false },
    });
  },
});

export default companiesSlice;

export const companiesSelect = (state) => state[ name ];
