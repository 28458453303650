// modules
import parser from 'html-react-parser';

// components
import { Box, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

// common
import { REQUEST_STATUSES } from 'common/constants';

// hooks
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// redux
import { getPrivacyPolicy, privacyPolicySelect } from 'redux-api/slices/privacyPolicySlice';

// style
import classes from './Privacy.module.scss';

// Ссылки для партнеров, пример заполнение [ { text: YOUR_TEXT, link: YOUR_LINK } ]
const session_patitions = [ ];

const Privacy = () => {
  const dispatch = useDispatch();

  const { privacyPolicyStatus, privacyPolicyData } = useSelector(privacyPolicySelect);

  const data = privacyPolicyData?.results?.[ 0 ];

  const isMobile = useMediaQuery('(max-width: 980px)');

  const [ open, setOpen ] = useState(false);
  const [ opacity, setOpacity ] = useState(1);

  const burger = open ? classes.b_opened : '';

  const handleClick = () => setOpen(prev => !prev);

  const transition = (number = opacity) => {
    if (isMobile) {
      if (open) {
        if (number < 1) {
          setOpacity(prev => prev + 0.1);
          setTimeout(() => transition(number + 0.1), 25);
        }
      } else {
        if (number > 0) {
          setOpacity(prev => prev - 0.1);
          setTimeout(() => transition(number - 0.1), 25);
        }
      }
    } else {
      setOpacity(1);
    }
  };

  const listwrapper = (
    <Box
      component="nav"
      aria-label='Основные разделы на странице'
      className={classes.listwrapper}
    >
      <ul role='menubar' aria-label='Основные разделы на странице'>
        {
          session_patitions.map(({ text, link }, k) => (
            <li key={k} role='none'>
              <Link to={link}>{text}</Link>
            </li>
          ))
        }
      </ul>
    </Box>
  );

  useEffect(() => {
    transition();

    dispatch(getPrivacyPolicy());
    return () => null;
  }, [ open, isMobile ]);

  if (privacyPolicyStatus !== REQUEST_STATUSES.SUCCEEDED) return null;
  return (
    <Box className={classes.main}>
      <Box className={classes.mobile} onClick={handleClick}>
        <Box className={classes.m_container}>
          <Box className={classes.m_name}>&nbsp;</Box>
          <Box className={`${classes.burger} ${burger}`}>
            {new Array(4).fill(null).map((_, k) => <span key={k}></span>)}
          </Box>
        </Box>
      </Box>
      <Box
        className={classes.top}
        style={{ display: open ? 'block' : isMobile ? 'none' : 'block', opacity }}
      >
        <Box className={classes.t_container}>
          <Box className={classes.topwrapper}>
            <Box className={classes.logowrapper}>
              <Box className={classes.logowrapper2}>
                <Box>
                  <Box
                    component='img'
                    src={data?.image}
                    alt={data?.image_alt}
                    className={classes.logo}
                  />
                </Box>
              </Box>
            </Box>
            {listwrapper}
            <Box className={classes.alladditional}>
              <Box className={classes.leftwrapper}>
                <Box className={classes.additionalwrapper}>
                  <Box className={classes.additionalitem}>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.rightwrapper}>
                <Box className={classes.additionalwrapper}>
                  <Box className={classes.additionalitem}>
                    {/* Ссылки на номера, оставил код на всякий */}
                    {/* {
                      phone_numbers?.map(({ value, label }, k) => (
                        <p key={k}><a href={'tel:' + value}>{label}</a></p>
                      ))
                    } */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.bottomwrapper}>
            {listwrapper}
          </Box>
        </Box>
      </Box>
      <Box className={classes.bottom}>
        <Box className={classes.b_container}>
          <Box className={classes.b_container2}>
            <Box className={classes.content}>
              <Box className={classes.text}>{parser(data?.text || '')}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Privacy;
