import { Box, IconButton, Modal, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OpenClose, modalAlertOpen, sendResume, vacancySelect } from 'redux-api/slices/VacancySlice';
import FileIcon from 'assets/icons/file.svg';
import CustomButton from '../CustomButton/CustomButton';
import Input from '../Input/Input';
import cls from './ModalResume.module.scss';


const maxSymbol = 300;
const maxLengthFile = 5_242_800;
const formKeyConst = {
  name: 'name',
  number: 'number',
  resume: 'resume',
  cover_letter: 'cover_letter',
};
const maxLengthName = 125;

const maskPhoneFormat = (phoneNumber) => {
  const x = phoneNumber
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,2})/);
  return `+996 (${x[ 2 ]}${x[ 3 ] && ') '}${x[ 3 ]}${x[ 4 ] && '-'}${x[ 4 ]}${x[ 5 ] && '-'}${x[ 5 ]}`;
};

const ModalResume = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [ symbolLength, setSymbolLength ] = useState(0);
  const [ error, setError ] = useState({
    name: false,
    number: false,
    resume: false,
  });
  const name = t('Name');
  const writeDown = t('SendMessage');

  const handleChangeFormSumbolLength = ({ target }) => {
    setSymbolLength(target.value.length);
  };

  const [ form, setForm ] = useState({
    vacancy_id: id,
    name: '',
    number: '',
    resume: '',
    cover_letter: '',
  });

  const { open } = useSelector(vacancySelect);

  const handleClose = () => dispatch(OpenClose());


  const handleChangeForm = ({ target }) => {
    if (target.name === formKeyConst.resume) {
      const file = target.files[ 0 ];
      if (file?.size > maxLengthFile) {
        setError(prev => ({ ...prev, [ target.name ]: true }));
        alert(t('FileSizeBig'));
        return;
      }
      if (file) {
        setForm(prev => {
          return { ...prev, [ target.name ]: file };
        });
        return;
      }
    }
    if (target.name === formKeyConst.number) {
      setForm(prev => {
        return { ...prev, [ target.name ]: maskPhoneFormat(target.value) };
      });
      if (error.number) {
        handleIsError(target.name);
      }
      return;
    }
    setForm(prev => {
      return { ...prev, [ target.name ]: target.value };
    });
    if (error.name) {
      handleIsError(target.name);
    }
  };

  const handleCancel = () => {
    setForm({
      vacancy_id: id,
      name: '',
      number: '',
      resume: '',
      cover_letter: '',
    });
    handleClose();
    setError({
      name: false,
      number: false,
      resume: false,
    });
  };

  const handleIsError = (name) => {
    let isError = false;
    if (name !== formKeyConst.cover_letter && form[ name ] === '') {
      setError(prev => {
        return { ...prev, [ name ]: true };
      });
      isError = true;
    } else if (name !== formKeyConst.cover_letter && form[ name ] !== '') {
      setError(prev => {
        return { ...prev, [ name ]: false };
      });
      isError = false;
    }
    if (name === formKeyConst.number && form[ name ].length !== 19) {
      setError(prev => {
        return { ...prev, [ name ]: true };
      });
      isError = false;
      if (name === formKeyConst.number && form[ name ].length >= 18) {
        setError(prev => {
          return { ...prev, [ name ]: false };
        });
        isError = true;
      }
    }
    if (name === 'all') {
      Object.keys(form).forEach(key => {
        if (key !== formKeyConst.cover_letter && form[ key ] === '') {
          setError(prev => {
            return { ...prev, [ key ]: true };
          });
          isError = true;
        }
        if (key === formKeyConst.number && form[ key ].length !== 19) {
          setError(prev => {
            return { ...prev, [ key ]: true };
          });
          isError = true;
        }
      });
    }
    return isError;
  };

  const handleSubmit = () => {
    const isError = handleIsError('all');
    if (!isError) {
      const formData = new FormData();
      Object.keys(form).forEach(key => {
        formData.append(key, form[ key ]);
      });
      dispatch(sendResume(formData));
      handleCancel();
      dispatch(modalAlertOpen());
    }
  };

  const handleDeleteFile = () => {
    setForm(prev => {
      return { ...prev, resume: '' };
    });
  };
  const isError = (name) => error[ name ] === true ? cls.error : '';

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box className={cls.ModalResume}>
          <Box className={cls.resumeHeader}>
            <Typography className={cls.title} variant='miniTitleBold'>
              {t('SendResume')}
            </Typography>
            <IconButton className={cls.btnClose} onClick={handleCancel} sx={{ p: 0 }} >
              <CloseIcon color='primary'/>
            </IconButton>
          </Box>
          <Box className={cls.inpWrapper}>
            <Typography className={`${cls.inpTitle} ${cls.inpError}`} variant='h6'>
              {name}
            </Typography>
            <Input
              error={error.name}
              value={form.name}
              required
              maxLength={maxLengthName}
              name={formKeyConst.name}
              onBlur={() => handleIsError(formKeyConst.name)}
              onChange={handleChangeForm}
              placeholder={name}
            />
          </Box>
          <Box className={cls.inpWrapper}>
            <Typography className={`${cls.inpTitle} ${cls.inpError}`} variant='h6'>
              {t('PhoneNumber')}
            </Typography>
            <Input
              error={error.number}
              value={form.number}
              onBlur={() => handleIsError(formKeyConst.number)}
              required
              name={formKeyConst.number}
              onChange={handleChangeForm}
              placeholder='+996'
            />
          </Box>
          <Box className={cls.inpWrapper}>
            {
              form.resume === ''
                ?
                <Box className={cls.wrapPinLabel} >
                  <label className={cls.pinLabel} htmlFor="pinFIle">
                    <h6 className={cls.pinTitlte}>{t('AttachResume')}</h6>
                    <AttachFileIcon fontSize='large' className={cls.attachIcon}/>
                  </label>
                  <p className={`${cls.pinSubtitle} ${isError(formKeyConst.resume)}`}>
                    Формат PDF, DOC, DOCS
                  </p>
                </Box>
                :
                <Box className={cls.wrapPinLabel} >
                  <Box className={cls.file}>
                    <img src={FileIcon} alt="" />
                    <span>
                      {form.resume?.name}
                    </span>
                    <IconButton onClick={handleDeleteFile} sx={{ p: 0 }} disableRipple={true}>
                      <CloseIcon color='primary'/>
                    </IconButton>
                  </Box>
                </Box>
            }
            <input
              type="file"
              name={formKeyConst.resume}
              onBlur={() => handleIsError(formKeyConst.resume)}
              onChange={handleChangeForm}
              hidden accept=".pdf,.doc,.docx"
              id="pinFIle"
              required
            />
          </Box>
          <Box className={cls.inpWrapper}>
            <Typography className={cls.inpTitle} variant='h6'>
              {writeDown}
            </Typography>
            <textarea
              value={form.cover_letter}
              name={formKeyConst.cover_letter}
              onChange={(e) => {
                handleChangeFormSumbolLength(e);
                handleChangeForm(e);
              }}
              onBlur={handleIsError}
              placeholder={t('YourText')}
              maxLength={maxSymbol}
              className={cls.textarea}
            />
            <p className={`${cls.pinSubtitle} ${cls.posLeft}`}>
              {symbolLength}/{maxSymbol}
            </p>
          </Box>
          <Box className={`${cls.inpWrapper} ${cls.btnWrapper}`}>
            <CustomButton
              onClick={handleCancel}
              theme='white'
              text={t('Cancel')}
            />
            <CustomButton
              onClick={handleSubmit}
              text={t('Send')}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ModalResume;
