import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classes from '../Title/Title.module.scss';


const CoursesStartSchedule = (props) => {
  const { t } = useTranslation();
  const { start_date, start_time, end_time, days_of_week, myClass } = props;

  return (
    <Box component='span' className={`${classes.date} ${myClass}`}>
      <Box component='span' className={classes.inner_date}>
        <Typography className={classes.graphic}>
          {
            start_date
            &&
            <Typography
              component='span'
            >
              {t('CourseStart')}
            </Typography>
          }
          <span>
            {start_date}
          </span>
        </Typography>
        <Typography
          className={classes.graphic}
        >
          {
            start_time
            &&
            end_time
            &&
            days_of_week
            &&
            <Typography
              component='span'
            >
              {'График: '}
            </Typography>
          }
          <span>
            {start_time.split(':').slice(0, 2).join(':')} {end_time.split(':').slice(0, 2).join(':')}
          </span>
          <span className={classes.lastBlock}>{days_of_week}</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default CoursesStartSchedule;
