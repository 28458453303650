import { useEffect, useState } from 'react';
import { FormControl, Select, MenuItem, Typography } from '@mui/material';
import { changeLang } from 'common/helpers';
import { ReactComponent as IconArrow } from 'assets/icons/chevron-down.svg';
import classes from './menu.module.scss';


const SelectItem = (props) => {
  const { menuContent, name, lang } = props;
  const [ extraMenu, setExtraMenu ] = useState(name || lang);

  useEffect(() => {
    if (name) {
      setExtraMenu(prev => name);
    }
  }, [ name ]);
  const handleMenuChange = (e) => {
    setExtraMenu(e.target.value);
  };

  return (
    <>
      <FormControl sx={{
        minWidth: 80,
        '& fieldset': { border: 'none' },
      }} >
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={extraMenu}
          onChange={handleMenuChange}
          className={classes.menu}
          autoWidth
          displayEmpty
          IconComponent={IconArrow}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{
            color: 'white !important',
            '& .MuiSelect-select > svg': { display: 'none' },
            '& .MuiSelect-outlined': { padding: '9px 40px 8px 12px' },
            '& .MuiSelect-icon': { color: 'white', top: '20%' },
            '&.Mui-selected:hover': {
              backgroundColor: '#2C2E35',
              color: 'inherit',
            },
            '&.MuiMenuItem-gutters': {
              backgroundColor: '#2C2E35 !important',
              color: 'inherit',
            },
          }}
        >
          {menuContent.map((item, i) => (
            <MenuItem
              sx={{
                background: '#2C2E35 !important',
                width: '103px',
                height: '42px',
                justifyContent: 'center',
                '&': {
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '10px',
                },
                ':hover': { background: '#2C2E35' },
                '&.Mui-selected span': {
                  color: 'white',
                  fontWeight: 700,
                },
              }}
              onClick={() => changeLang(item.id)}
              value={item.titleMain}
              key={i}
            >
              <item.Icon/>
              <Typography component='span' sx={{ color: 'white' }}>
                {item.titleMain}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

};

export default SelectItem;
