import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from './Input.module.scss';


const Input = ({ error, ...props }) => {
  const { t } = useTranslation();
  const errorText = t('FillField');
  return <div>
    <input className={`${cls.inp} ${error ? cls.inpError : ''}`} {...props} />
    {error && <span className={cls.Error}>{errorText}</span>}
  </div>;
};
export default Input;
