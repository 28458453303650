import { configureStore } from '@reduxjs/toolkit';
import { requester } from 'requester';
import { reducerObj } from 'redux-api';


export const store = configureStore({
  reducer: reducerObj,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ thunk: { extraArgument: requester } }),
});
