import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'after-courses';

const ENDPOINTS = {
  headline: '/api/v1/headline/',
  course_results: '/api/v1/course_results/',
  dear_geeks: 'api/v1/footer_content/'
};

export const getHeadline = createAsyncThunk(
  `${name}/getHeadline`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.headline}`);
    return { results: response.data };
  }
);

export const getCourseResults = createAsyncThunk(
  `${name}/getCourseResults`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.course_results}`);
    return { results: response.data };
  }
);

export const getDearContent = createAsyncThunk(
  `${name}/getDearContent`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.dear_geeks}`);
    return { results: response.data };
  }
);

const initialState = {
  headlineStatus: REQUEST_STATUSES.NOT_REQUESTED,
  headlineData: {},
  headlineError: null,
  courseResultsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  courseResultsData: {},
  courseResultsError: null,
  dearContentStatus: REQUEST_STATUSES.NOT_REQUESTED,
  dearContentData: {},
  dearContentError: null,
};

const afterCoursesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getHeadline, {
      status: 'headlineStatus',
      data: 'headlineData',
      error: 'headlineError',
    });
    addQueryCases(builder, getCourseResults, {
      status: 'courseResultsStatus',
      data: 'courseResultsData',
      error: 'courseResultsError'
    });
    addQueryCases(builder, getDearContent, {
      status: 'dearContentStatus',
      data: 'dearContentData',
      error: 'dearContentError'
    });
  }
});

export default afterCoursesSlice;

export const afterCoursesSelect = (state) => state[ name ];
