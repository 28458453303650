import { Container, Box, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/UI/CustomButton/CustomButton';
import { BITRIX } from 'common/constants';
import parse from 'html-react-parser';
import classes from './InfoPartialComponent.module.scss';


const InfoPartialComponent = (props) => {

  const { t } = useTranslation();
  const {
    title,
    arrayCardsInfo,
    theme = 'black',
    themeBtn,
    BtnIsArrow,
    titleUnderText,
    btnText = t('UpLesson'),
    isBtn = true,
    aosData = {},
    myClass
  } = props;

  const themes = {
    btn: {
      yellow: 'yellow',
      white: 'white',
      transparent: 'transparent',
    },
    theComponent: {
      white: 'white',
      black: 'black',
    },
  };

  const componentColors = {
    white: classes.tempWhiteBg,
    dark: classes.tempDarkBg,
  };
  const theTheme = theme === themes.theComponent.white ? componentColors.white : componentColors.dark;
  const theThemeBtn = () => {
    switch (themeBtn) {
    case themes.btn.yellow :
      return themes.btn.yellow;
    case themes.btn.white :
      return themes.btn.white;
    case themes.btn.transparent :
      return themes.btn.transparent;
    default:
      return themes.btn.yellow;
    }
  };

  const cardsArray = () => {

    return arrayCardsInfo?.map((cardInfo, idx) =>
    {
      const rowOrReverse = idx % 2 === 0 ? classes.leftToRight : classes.rightToLeft;

      const aosData = {
        'data-aos': 'fade-up',
        'data-aos-duration': 800,
        'data-aos-offset': 200,
        'data-aos-delay': idx % 2 === 0 ? 50 * ((idx % 2) + 1) : 100 * ((idx % 2) + 1),
      };
      return (
        <TableRow key={idx} className={`${rowOrReverse} ${classes.tableMobileMode}`}>
          <TableCell
            className={`${classes.rightText} ${theme === 'black' ? classes.whiteText : ''}`}
            {...aosData}
          >
            {parse(cardInfo.text)}
          </TableCell>
          <TableCell
            className={classes.rightImage}
            {...aosData}
            data-aos-delay={idx % 2 === 0 ? 100 * ((idx % 2) + 1) : 50 * ((idx % 2) + 1)}
          >
            <Box component="img" src={cardInfo.image} alt={cardInfo.image_alt} />
          </TableCell>
        </TableRow>
      );
    }
    );
  };

  return (
    <Box className={`${theTheme} ${classes.sectionBlock} ${myClass}`}>
      <Container>
        <Box className={classes.mainBlock}>
          <Box className={classes.leftBlock}>
            <h2 className={classes.mainTitle} data-aos='fade-up' data-aos-duration={800}>
              {title}
            </h2>
            {
              titleUnderText && <p
                className={classes.titleUnder}
                {...aosData}
                data-aos-delay={50}
                data-aos-offset={0}
              >{titleUnderText}</p>
            }

            {
              isBtn
                &&
                <Box className={classes.buttonBox}>
                  <CustomButton
                    text={btnText}
                    path={BITRIX.trialLesson}
                    theme={theThemeBtn()}
                    isArrow={BtnIsArrow}
                  />
                </Box>
            }
          </Box>
          <Table>
            <TableBody className={classes.tb}>
              {cardsArray()}
            </TableBody>
          </Table>
        </Box>
      </Container>
    </Box>
  );
};

export default InfoPartialComponent;
