import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'student';

const ENDPOINTS = {
  student: (slug, id) => `/api/v1/career_center/directions/${slug}/${id}/`,
  add: (slug, id) => `/api/v1/career_center/directions/${slug}/${id}/create_request_alumni/`
};

export const getStudent = createAsyncThunk(
  `${name}/getStudent`,
  async ({ slug, id }, { extra: api }) => {
    const token = JSON.parse(localStorage.getItem('token'));

    const token_type = token?.token_type;
    const access_token = token?.access_token;

    const response = await api.get(`${ENDPOINTS.student(slug, id)}`,
      { headers: { Authorization: `${token_type} ${access_token}` } }
    );
    return response.data;
  }
);

export const addRequest = createAsyncThunk(
  `${name}/addRequest`,
  async ({ slug, id, alumni, name, number, nameCompany, nameUser }, { extra: api }) => {
    const token = JSON.parse(localStorage.getItem('token'));

    const token_type = token?.token_type;
    const access_token = token?.access_token;
    const response = await api.post(`${ENDPOINTS.add(slug, id)}`,
      { alumni, name, number, company_name: nameCompany },
      { headers: { Authorization: `${token_type} ${access_token}` } }
    );

    return response;
  }
);

const initialState = {
  [ name + 'Status' ]: REQUEST_STATUSES.NOT_REQUESTED,
  [ name + 'Data' ]: {},
  [ name + 'Error' ]: null
};

const studentSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetStudent: (state) => {
      state[ name + 'Data' ] = {};
    }
  },
  extraReducers(builder) {
    addQueryCases(builder, getStudent, {
      status: [ name + 'Status' ],
      data: [ name + 'Data' ],
      error: [ name + 'Error' ],
      options: { concat: false }
    });
  }
});

export default studentSlice;

export const studentSelect = state => state?.[ name ];
export const { resetStudent } = studentSlice.actions;
