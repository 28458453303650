import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import cls from './VacancyCard.module.scss';


export const VacancyCard = ({ title, salary, reffer, aosData }) => {
  const { t } = useTranslation();
  return (
    <div className={cls.card} {...aosData}>
      <p className={cls.title} {...aosData}>{title}</p>
      <p className={cls.subtitle} {...aosData}>{t('Wage')}: {salary}</p>
      <Link className={cls.link} to={reffer} {...aosData} data-aos-delay={50}>
        {t('More')}
        <ArrowForwardIcon/>
      </Link>
    </div>
  );
};
