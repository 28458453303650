import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'editStudent';

const ENDPOINTS = {
  profile: '/api/v1/profile/',
  skills: '/api/v1/career_center/skills/',
  info: '/api/v1/profile/update/',
  portfolio: '/api/v1/career_center/portfolio/'
};

const studentAuth = () => {
  const token = JSON.parse(sessionStorage.getItem('token'));
  if (!token) throw new Error('No token found');
  return { headers: { Authorization: `${token?.token_type} ${token?.access_token}` } };
};

export const getEditStudent = createAsyncThunk(
  `${name}/getEditStudent`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.profile,
      studentAuth()
    );
    return response.data;
  }
);

export const updateEditStudent = createAsyncThunk(
  `${name}/updateEditStudent`,
  async (updateFields, { extra: api }) => {
    const response = await api.patch(ENDPOINTS.profile,
      updateFields,
      studentAuth()
    );
    return response.data;
  }
);

export const getEditStudentSkills = createAsyncThunk(
  `${name}/getEditStudentSkills`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.skills,
      studentAuth()
    );
    return response.data.results;
  }
);

export const searchSkills = createAsyncThunk(
  `${name}/searchSkills`,
  async (text, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.skills}?search=${text}`,
      studentAuth()
    );
    return response.data.results;
  }
);

export const getEditStudentInfo = createAsyncThunk(
  `${name}/getEditStudentInfo`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.info,
      studentAuth()
    );
    return response.data;
  }
);

export const updateEditStudentInfo = createAsyncThunk(
  `${name}/updateEditStudentInfo`,
  async (data, { extra: api }) => {
    const response = await api.post(ENDPOINTS.info,
      data,
      studentAuth()
    );
    return response.data;
  }
);

export const deleteEditStudentInfo = createAsyncThunk(
  `${name}/deleteEditStudentInfo`,
  async (data, { extra: api }) => {
    try {
      const response = await api.patch(ENDPOINTS.info, data, studentAuth());
      return response.data;
    } catch (error) {
      console.error('Error deleting student info:', error);
    }
  }
);

export const getEditStudentPortfolio = createAsyncThunk(
  `${name}/getEditStudentPortfolio`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.portfolio,
      studentAuth()
    );
    return response.data.results;
  }
);

export const searchPortfolio = createAsyncThunk(
  `${name}/searchPortfolio`,
  async (text, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.portfolio}?search=${text}`,
      studentAuth()
    );
    return response.data.results;
  }
);

const initialState = {
  [ name + 'Status' ]: REQUEST_STATUSES.NOT_REQUESTED,
  [ name + 'Data' ]: {},
  [ name + 'Error' ]: null,

  skills: [],
  selectedSkills: [],
  customSkills: [],
  searchSkills: [],
  skillsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  skillsError: null,
  removedSkills: [],

  info: {
    student_portfolio: [],
    student_projects: [],
    skills: [],
    custom_skills: []
  },
  infoStatus: REQUEST_STATUSES.NOT_REQUESTED,
  infoError: null,

  portfolio: [],
  customPortfolio: [],
  selectedPortfolio: [],
  searchPortfolio: [],
  removedPortfolio: [],
  portfolioStatus: REQUEST_STATUSES.NOT_REQUESTED,
  portfolioError: null,

  deleteInfo: {},
  deleteInfoStatus: REQUEST_STATUSES.NOT_REQUESTED,
};

const editStudentSlice = createSlice({
  name,
  initialState,
  reducers: {
    selectSkill(state, action) {
      const skillId = action.payload.id;

      const isSkillAlreadySelected = state.selectedSkills.some(skill => skill.id === skillId);

      if (!isSkillAlreadySelected) {
        state.selectedSkills.push({ ...action.payload, isCustom: false, noClient: false });
        state.skills = state.skills.filter(skill => skill.id !== skillId);
        state.searchSkills = state.searchSkills.filter(skill => skill.id !== skillId);

        state.removedSkills = state.removedSkills.filter(skill => skill.id !== skillId);
      }
    },
    addCustomSkill(state, action) {
      state.customSkills.push({ ...action.payload, noClient: false });
      state.selectedSkills.push({ ...action.payload, isCustom: true, noClient: false });
    },
    removeSelectedSkill(state, action) {
      const skillId = action.payload.id;

      state.selectedSkills = state.selectedSkills.filter(skill => skill.id !== skillId);

      state.customSkills = state.customSkills.filter(skill => skill.id !== skillId);

      const isOriginalSkills = state.info?.skills.some(
        skill => skill.id === skillId) ||
      state.info?.custom_skills.some(
        skill => skill.id === skillId);

      if (isOriginalSkills) {
        state.removedSkills.push(action.payload);
      }
      if (state.customSkills.some(skill => skill.id === skillId)) return;

      state.skills.push(action.payload);
    },

    // Portfolio
    selectPortfolio: (state, action) => {
      const portfolioId = action.payload.id;

      const isPortfolioAlreadySelected = state.selectedPortfolio.some(portfolio => portfolio.id === portfolioId);

      const isOriginalAlreadySelected = state.portfolio.some(portfolio => portfolio.id === portfolioId);

      if (!isOriginalAlreadySelected) {
        state.customPortfolio.push({ ...action.payload, isCustom: true });
      } else {
        if (!isPortfolioAlreadySelected) {
          state.selectedPortfolio.push({ ...action.payload, isDefault: true, noClient: false });
          state.searchPortfolio = [];

          state.removedPortfolio = state.removedPortfolio.filter(item => item.id !== portfolioId);
        }
      }
    },
    removeSelectedPortfolio(state, action) {
      const portfolioId = action.payload.id;

      state.selectedPortfolio = state.selectedPortfolio.filter(item => item.id !== portfolioId);
      state.customPortfolio = state.customPortfolio.filter(item => item.id !== portfolioId);

      const isOriginalPortfolio = state.info?.student_portfolio.some(
        item => item.id === portfolioId) ||
      state.info?.student_projects.some(
        item => item.id === portfolioId);

      if (isOriginalPortfolio) {
        state.removedPortfolio.push(action.payload);
      }
      if (state.customSkills.some(skill => skill.id === portfolioId)) return;
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, getEditStudent, {
      status: [ name + 'Status' ],
      data: [ name + 'Data' ],
      error: [ name + 'Error' ],
      options: { concat: false }
    });
    addQueryCases(builder, deleteEditStudentInfo, {
      status: [ 'deleteInfoStatus' ],
      data: [ 'deleteInfo' ],
      options: { concat: false }
    });
    builder.addCase(updateEditStudent.fulfilled, (state, action) => {
      state[ name + 'Data' ] = {
        ...state[ name + 'Data' ],
        ...action.payload
      };

      state[ name + 'Status' ] = REQUEST_STATUSES.SUCCESS;
    });

    builder.addCase(updateEditStudent.pending, (state) => {
      state[ name + 'Status' ] = REQUEST_STATUSES.LOADING;
    });

    builder.addCase(updateEditStudent.rejected, (state, action) => {
      state[ name + 'Status' ] = REQUEST_STATUSES.FAILED;
      state[ name + 'Error' ] = action.error.message;
    });
    // Skills
    builder.addCase(getEditStudentSkills.fulfilled, (state, action) => {
      const selectedSkillIds = state.selectedSkills.map(skill => skill.id);

      state.skills = action.payload.filter(
        skill => !selectedSkillIds.includes(skill.id)
      );
      state.skillsStatus = REQUEST_STATUSES.SUCCEEDED;
    });
    builder.addCase(searchSkills.fulfilled, (state, action) => {
      const selectedSkillIds = state.selectedSkills.map(skill => skill.id);

      state.searchSkills = action.payload.filter(
        skill => !selectedSkillIds.includes(skill.id)
      );
      state.skillsStatus = REQUEST_STATUSES.SUCCEEDED;
    });
    builder.addCase(getEditStudentInfo.fulfilled, (state, action) => {
      const {
        skills = [],
        custom_skills = [],
        student_portfolio = [],
        student_projects = [],
      } = action.payload;

      const studentProject = student_projects.map((item) => {
        return {
          id: item.id,
          title: item.project_name,
          url: item.url,
          isPortfolio: false,
          noClient: true,
        };
      });

      const studentPortfolio = student_portfolio.map((item) => {
        return {
          id: item.id,
          title: item.portfolio,
          url: item.url,
          isPortfolio: true,
          noClient: true,
        };
      });

      const studentCustomSkills = custom_skills.map((item) => {
        return {
          id: item.id,
          title: item.title,
          isSkill: false,
          noClient: true,
        };
      });

      const studentSkills = skills.map((item) => {
        return {
          id: item.id,
          title: item.title,
          isSkill: true,
          noClient: true,
        };
      });

      const studentSkillIds = new Set([
        ...studentSkills.map(item => item.id), ...studentCustomSkills.map(item => item.id)
      ]);

      state.skills = state.skills.filter(
        skill => !studentSkillIds.has(skill.id)
      );

      state.selectedSkills = [ ...studentSkills, ...studentCustomSkills ];
      state.selectedPortfolio = [ ...studentProject, ...studentPortfolio ];
      state.skills = state.skills.filter(
        skill => !studentSkillIds.has(skill.id)
      );
      state.info = action.payload;
      state.infoStatus = REQUEST_STATUSES.SUCCEEDED;
    });
    builder.addCase(updateEditStudentInfo.fulfilled, (state, action) => {
      const { skills, student_portfolio, student_projects, custom_skills } = action.payload;

      state.info.skills = skills || [];
      state.info.student_portfolio = student_portfolio || [];
      state.info.student_projects = student_projects || [];
      state.info.custom_skills = custom_skills || [];

      state.infoStatus = REQUEST_STATUSES.SUCCESS;
    });
    // Portfolio
    builder.addCase(getEditStudentPortfolio.fulfilled, (state, action) => {
      state.portfolio = action.payload.filter(
        item => !item.selectedPortfolio?.some(selectPortfolio => selectPortfolio.id === item.id)
      );
      state.portfolioStatus = REQUEST_STATUSES.SUCCEEDED;
    });
    builder.addCase(searchPortfolio.fulfilled, (state, action) => {
      state.searchPortfolio = action.payload;
      state.portfolioStatus = REQUEST_STATUSES.SUCCEEDED;
    });
  }
});

export default editStudentSlice;

export const {
  selectSkill,
  addCustomSkill,
  removeSelectedSkill,

  selectPortfolio,
  removeSelectedPortfolio
} = editStudentSlice.actions;

export const editStudentSelect = state => state?.[ name ];
