import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'geeks_jr';

const ENDPOINTS = {
  main: 'api/v1/geeks_jr/main_block',
  why: 'api/v1/geeks_jr/why_geeks_jr',
  missions: 'api/v1/geeks_jr/missions',
  courses: 'api/v1/geeks_jr/courses',
  trial_lessons: 'api/v1/geeks_jr/trial_lessons',
  teachers: 'api/v1/geeks_jr/teachers',
  reviews: 'api/v1/geeks_jr/reviews',
};

export const getGSMain = createAsyncThunk(
  `${name}/getGSMain`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.main}`);
    return response.data;
  }
);
export const getGSWhy = createAsyncThunk(
  `${name}/getGSWhy`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.why}`);
    return response.data;
  }
);
export const getGSMission = createAsyncThunk(
  `${name}/getGSMission`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.missions}`);
    return response.data;
  }
);
export const getGSTeachers = createAsyncThunk(
  `${name}/getGSTeachers`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.teachers}`);
    return response.data;
  }
);
export const getGSTrialLessons = createAsyncThunk(
  `${name}/getGSTrialLessons`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.trial_lessons}`);
    return response.data;
  }
);
export const getGSCourses = createAsyncThunk(
  `${name}/getGSCourses`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.courses}`);
    return response.data;
  }
);
export const getGSReviews = createAsyncThunk(
  `${name}/getGSReviews`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.reviews}`);
    return response.data;
  }
);
const initialState = {
  mainStatus: REQUEST_STATUSES.NOT_REQUESTED,
  main: {},
  mainDataError: null,
  whyStatus: REQUEST_STATUSES.NOT_REQUESTED,
  why: {},
  whyDataError: null,
  missionsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  missions: {},
  missionsDataError: null,
  trialLessonsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  trialLessons: {},
  trialLessonsDataError: null,
  teachersStatus: REQUEST_STATUSES.NOT_REQUESTED,
  teachers: {},
  teachersDataError: null,
  coursesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  courses: {},
  coursesDataError: null,
  reviewsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  reviews: {},
  reviewsDataError: null,
};
const geeksJuniorSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getGSMain, {
      status: 'mainStatus',
      data: 'main',
      error: 'mainDataError',
      options: { concat: false }
    });
    addQueryCases(builder, getGSWhy, {
      status: 'whyStatus',
      data: 'why',
      error: 'whyDataError',
      options: { concat: false }
    });
    addQueryCases(builder, getGSMission, {
      status: 'missionsStatus',
      data: 'missions',
      error: 'missionsDataError',
      options: { concat: false }
    });
    addQueryCases(builder, getGSTeachers, {
      status: 'teachersStatus',
      data: 'teachers',
      error: 'teachersDataError',
      options: { concat: false }
    });
    addQueryCases(builder, getGSTrialLessons, {
      status: 'trialLessonsStatus',
      data: 'trialLessons',
      error: 'trialLessonsDataError',
      options: { concat: false }
    });
    addQueryCases(builder, getGSCourses, {
      status: 'coursesStatus',
      data: 'courses',
      error: 'coursesDataError',
      options: { concat: false }
    });
    addQueryCases(builder, getGSReviews, {
      status: 'reviewsStatus',
      data: 'reviews',
      error: 'reviewsDataError',
      options: { concat: false }
    });
  }
});

export default geeksJuniorSlice;

export const geeksJuniorSelect = state => state?.[ name ];
