import React from 'react';
import { Box } from '@mui/material';

// Assets
import { ReactComponent as Ru } from 'assets/icons/ru_flag.svg';
import { ReactComponent as Kr } from 'assets/icons/kr_flag.svg';

import SelectItem from '../SelectItem/SelectItem';

import classes from '../../header.module.scss';


const langArr =
[
  // eslint-disable-next-line
  { id: 'ru', title: 'РУССКИЙ', titleMain: 'РУС', Icon: Ru },
  { id: 'ky', title: 'КЫРГЫЗСКИЙ', titleMain: 'КЫР', Icon: Kr },
];

const LanguageMenu = () => {

  const lang = localStorage.getItem('lang') || 'ru';
  const selectedLang = langArr.find(item => item.id === lang);

  return (
    <Box className={classes.burgerMenu} sx={{ '& .MuiPaper-root': { background: '#2C2E35' } }}>
      <SelectItem
        lang={selectedLang.title}
        name={selectedLang.titleMain}
        menuContent={langArr}
      />
    </Box>
  );
};

export default LanguageMenu;
