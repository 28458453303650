// Components
import { Zoom } from '@mui/material';
// Hooks
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Selects
import { detailSocialMedia } from 'redux-api/slices/footerSlice';
// Assets
import { ReactComponent as AnchorIcon } from 'assets/icons/anchorButton.svg';
import imgCall from '../../../assets/icons/actionBtnCall.svg';
import sendData from '../../../assets/icons/sendData.svg';
// Styles
import { BITRIX } from '../../../common/constants';
import classes from './AnchorButton.module.scss';


const AnchorButton = () => {
  const [ isVisible, setIsVisible ] = useState(false);
  const [ rotate, setRotate ] = useState(false);
  const { socialMedia } = useSelector(detailSocialMedia);

  const handleScroll = () => {
    const scrolledHeight = window.pageYOffset;
    if (scrolledHeight > window.innerHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className={classes.anchorButton}>
      <div
        className={`${classes.actionBtn} ${rotate ? classes.startBtn : ''}`}
        style={!isVisible ? { bottom: '73px' } : {}}
      >
        <img src={imgCall} alt='call'/>
        <div className={classes.roundPush}>
          {socialMedia?.slice(0, 2)?.map((social, index) => (
            <Zoom in={rotate} style={{ transitionDelay: rotate && '700ms' }} key={index} >
              <a href={social?.link} target='_blank' rel="noreferrer">
                <img src={social?.active_icon} alt={social?.active_icon_alt} />
              </a>
            </Zoom>
          ))
          }
          <Zoom in={rotate} className={classes.activeBlock} style={{ transitionDelay: rotate && '700ms' }}>
            <a href={BITRIX.anchor} target='_blank' rel="noreferrer">
              <img src={sendData} alt='call'/>
            </a>
          </Zoom>
        </div>
        <div className={classes.actionBlock} onClick={() => setRotate(prev => !prev)}></div>
      </div>
      <button onClick={() => isVisible && scrollToTop()}
        className={`${classes.btn} ${isVisible ? classes.visible : ''}`}>
        <AnchorIcon/>
      </button>
    </div>
  );
};

export default AnchorButton;
