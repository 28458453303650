import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'faq';

const ENDPOINTS = { Faq: '/api/v1/faq/' };


export const getFaq = createAsyncThunk(
  `${name}/getFaqBySlug`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.Faq);

    return response.data;
  },
);

const initialState = {
  faqStatus: REQUEST_STATUSES.NOT_REQUESTED,
  faq: [],
  faqError: null,
};

const FaqSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getFaq, {
      status: 'faqStatus',
      data: 'faq',
      error: 'faqError',
      options: { concat: false },
    });
  },
});

export default FaqSlice;

export const faqSelect = (state) => state[ name ];
