import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'privacyPolicy';

const ENDPOINTS = { data: '/api/v1/privacy_policy/' };

export const getPrivacyPolicy = createAsyncThunk(
  `${name}/getPrivacyPolicy`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.data}`);

    return response.data;
  },
);

const initialState = {
  [ name + 'Status' ]: REQUEST_STATUSES.NOT_REQUESTED,
  [ name + 'Data' ]: {},
  [ name + 'Error' ]: null,
};

const privacyPolicySlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getPrivacyPolicy, {
      status: [ name + 'Status' ],
      data: [ name + 'Data' ],
      error: [ name + 'Error' ],
      options: { concat: false },
    });
  },
});

export default privacyPolicySlice;

export const privacyPolicySelect = (state) => state[ name ];
