// module
import React from 'react';
import { Box, Divider, Typography, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
// style
import CustomButton from 'components/UI/CustomButton/CustomButton';
import { BITRIX } from 'common/constants';
import { generateRel, useChangeFromMedia } from 'common/helpers';
import CoursesStartSchedule from '../coursesStartSchedule/CoursesStartSchedule';
import classes from './Title.module.scss';


const Title = (props) => {

  const { t } = useTranslation();

  const { data, className } = props;

  const {
    title = '',
    secondWord = t('InBishkek'),
    description = '',
    start_date = '',
    start_time = '',
    end_time = '',
    days_of_week = '',
    button = true,
    extended_name,
    isTechnology,
    addToSecondWord,
  } = data;

  const TabletMobile = useChangeFromMedia(false, true, false, true, true);

  const MainTitle = title?.includes(secondWord) ? title.slice(0, -9) : title;

  const MainTitleTechnology = extended_name?.split(' ');
  const MainTitleTechnologyUp = MainTitleTechnology?.slice(0, 2).join(' ');
  const MainTitleTechnologyDown = MainTitleTechnology?.slice(2).join(' ');


  return (
    <Box className={`${classes.block} ${className}`}>
      <Divider className={classes.divider}/>
      <Container>
        <Box>
          {
            !!isTechnology
              ?
              <Box className={classes.technologyBox}>
                <Box className={classes.technologyUpperBlock}>
                  <Typography
                    className={`${classes.title} ${classes.inFlex}`}
                    variant='h1'
                    data-aos='fade-up'
                  >
                    {MainTitleTechnologyUp}
                    {
                      !TabletMobile
                      &&
                      <CoursesStartSchedule
                        start_date={start_date}
                        start_time={start_time}
                        end_time={end_time}
                        days_of_week={days_of_week}
                        myClass={classes.nothing}
                      />
                    }
                  </Typography>
                </Box>

                <Typography variant='h1' className={classes.yellowText}>
                  {MainTitleTechnologyDown}
                </Typography>
              </Box>

              :
              <Typography className={classes.title} variant='h1' data-aos='fade-up'>
                {MainTitle + ' '}
                <Typography variant='span' className={classes.whiteText}>
                  {addToSecondWord
                   && <Typography variant='span' className={classes.title}> {addToSecondWord}</Typography>}
                  {` ${secondWord}`}
                  {
                    !TabletMobile
                    &&
                    <CoursesStartSchedule
                      start_date={start_date}
                      start_time={start_time}
                      end_time={end_time}
                      days_of_week={days_of_week}
                      myClass={classes.nothing}
                    />
                  }
                </Typography>
              </Typography>
          }


          <Typography
            className={classes.description}
            data-aos='fade-up'
            data-aos-duration={1000}
          >
            {description}
          </Typography>
          {
            TabletMobile
            &&
            <>
              <CoursesStartSchedule
                start_date={start_date}
                start_time={start_time}
                end_time={end_time}
                days_of_week={days_of_week}
                myClass={classes.mlNone}
              />
              <br />
            </>
          }
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          {button && <a href={BITRIX.course} alt='bitrix link' target = '_blank' {...generateRel(true)}>
            <CustomButton text={t('UpLesson')} theme='yellow' isArrow={true}/>
          </a>
          }
        </Box>
      </Container>
    </Box>
  );
};

export default Title;
