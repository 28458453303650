import { externalRecourse, PATHS } from 'common/constants';
import { generateRel } from 'common/helpers';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AdvantageMenu from '../AdvatageMenu/AdvantageMenu';
import { LanguageMenu } from '../LanguageMenu';
import classes from './navigation.module.scss';


const Navigation = ({ headerPaths }) => {
  const { t } = useTranslation();
  return (
    <nav className={classes.nav}>
      <span>
        <NavLink
          title={t('Courses')}
          className={({ isActive }) =>
            isActive ? classes.activeLink : classes.link
          }
          to={PATHS.allcourses}
          target={false ? '_blank' : ''}
          {...generateRel(false)}
        >
          {t('Courses')}
        </NavLink>
      </span>
      <AdvantageMenu />
      {headerPaths.map((item, i) => (
        <span key={i} className={classes.linkWrap}>
          <NavLink
            title={item.name}
            className={({ isActive }) =>
              isActive ? classes.activeLink : classes.link
            }
            to={item.url}
            target={item.target ? '_blank' : ''}
            {...generateRel(item.target)}
          >
            {item.name}
          </NavLink>
        </span>
      ))}
      <span>
        <NavLink
          title="GeekStudio"
          className={classes.link}
          to={externalRecourse.geekstudio}
          target="_blank"
        >
          GeekStudio
        </NavLink>
      </span>
      <LanguageMenu />
    </nav>
  );
};

export default Navigation;
