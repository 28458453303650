import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'careerCenter';

const ENDPOINTS = {
  main: '/api/v1/career_center/',
  add: '/api/v1/career_center/create_request/',
};

export const getCareerCenter = createAsyncThunk(
  `${name}/getCareerCenter`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.main}`);
    return response.data;
  }
);

export const addApplication = createAsyncThunk(
  `${name}/addApplication`,
  async (data, { extra: api }) => {
    const response = await api.post(`${ENDPOINTS.add}`, data);
    return response;
  }
);

const initialState = {
  [ name + 'Status' ]: REQUEST_STATUSES.NOT_REQUESTED,
  [ name + 'Data' ]: {},
  [ name + 'Error' ]: null
};

const careerCenterSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getCareerCenter, {
      status: [ name + 'Status' ],
      data: [ name + 'Data' ],
      error: [ name + 'Error' ],
      options: { concat: false }
    });
  }
});

export default careerCenterSlice;

export const careerCenterSelect = state => state?.[ name ];
