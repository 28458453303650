import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'trial_lesson';

const ENDPOINTS = { trial_lesson: '/api/v1/trial_lessons/' };

export const getTrialLessonList = createAsyncThunk(
  `${name}/getTrialLessonList   `,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.trial_lesson}`);

    return response.data;
  },
);

const initialState = {
  trialLessonListStatus: REQUEST_STATUSES.NOT_REQUESTED,
  trialLessonList: {},
  trialLessonListError: null,
};

const trialLessonSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getTrialLessonList, {
      status: 'trialLessonStatus',
      data: 'trialLessonList',
      error: 'trialLessonError',
      options: { concat: false },
    });
  },
});

export default trialLessonSlice;

export const trialLessonSelect = (state) => state[ name ];
