import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { modalAlertClose, vacancySelect } from 'redux-api/slices/VacancySlice';
import Icon from 'assets/icons/okeyIcon.svg';
import { useNavigate } from 'react-router-dom';
import { PATHS, REQUEST_STATUSES } from 'common/constants';
import Loader from 'components/Loader/Loader';
import CustomButton from '../CustomButton/CustomButton';
import classes from './ModalAlert.module.scss';


const ModalAlert = ({ children }) => {
  const { modalAlert, sendResumeStatus } = useSelector(vacancySelect);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClose = () => dispatch(modalAlertClose());

  const nav = useNavigate();
  return (
    <Modal
      open={modalAlert}
      onClose={handleClose}
    >
      {
        sendResumeStatus === REQUEST_STATUSES.REQUESTED
          ?
          <Loader/>
          :
          <Box className={classes.ModalResume}>
            <Box className={classes.cancelButton} >
              <IconButton onClick={handleClose} sx={{ p: 0 }}>
                <CloseIcon color='primary'/>
              </IconButton>
            </Box>
            <Box className={`${classes.center} ${classes.top}`}>
              <img src={Icon} alt="" />
            </Box>
            <Box className={classes.center}>
              <Typography component='h4' variant='miniTitleMedium'>
                {t('SendResume2')}
              </Typography>

              <Typography variant='h6'>
                {t('ManagerWillContactYou')}
              </Typography>
            </Box>
            <Box className={`${classes.center} ${classes.centerBtn}`}>
              <CustomButton
                onClick={() => {
                  nav(PATHS.main);
                  handleClose();
                }}
                isRounded={true}
                text={t('ToMain')}
              />
            </Box>
          </Box>
      }
    </Modal>
  );
};
export default ModalAlert;
