import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'education';

const ENDPOINTS = { education: '/api/v1/education_process/' };

export const getEducationProcess = createAsyncThunk(
  `${name}/getEducationProcess`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.education);
    return response.data;
  }
);

const initialState = {
  educationProcessStatus: REQUEST_STATUSES.NOT_REQUESTED,
  educationProcess: [],
  educationProcessError: null,
};

const educationProcessSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getEducationProcess, {
      status: 'educationProcessStatus',
      data: 'educationProcess',
      error: 'educationProcessError',
      options: { concat: true },
    });
  },
});

export default educationProcessSlice;

export const detailEducationProcess = (state) => state[ name ];
