import { Box } from '@mui/material';
import { Header } from 'components/Header';
import { Suspense, lazy, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';
import Loader from 'components/Loader/Loader';
import { AnchorButton } from 'components/UI';


const Footer = lazy(() => import('components/Footer/Footer'));
const LocationMap = lazy(() => import('components/LocationMap/LocationMap'));

const Layout = ({ isMap = false }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => null;
  }, [ location ]);

  return (
    <Box>
      <Header />
      <Suspense fallback={<Loader />}>
        <Outlet/>
        {isMap &&
        <LocationMap/>
        }
        <Footer/>
      </Suspense>
      <AnchorButton/>
    </Box>
  );
};
export default Layout;
