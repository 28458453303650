import React, { useEffect, useRef, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Container, IconButton } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useChangeFromMedia } from 'common/helpers';
import classes from './CustomInfoBlock.module.scss';


const CustomInfoBlock = (props) => {
  const {
    text,
    children,
    myClass,
    swiperOpts,
    isContainer = false,
    isDark = false,
    isYellow = false,
    subTitle,
    stylesForMobile,
    isButtons = false,
    myClassTitle = '',
    myClassButtons = '',
  } = props;
  const swiperRef = useRef(null);
  const [ swiper, setSwiper ] = useState(null);
  const showBySizeDesktop = isButtons ? true : children?.length <= 3 ? false : true;
  const showBySize = useChangeFromMedia(showBySizeDesktop, true, showBySizeDesktop, true, showBySizeDesktop);
  const options = {
    direction: 'horizontal',
    mousewheel: true,
    spaceBetween: 40,
    grabCursor: true,
    scrollbar: { draggable: showBySize },
    loop: showBySize,
    initialSlide: 0,
    ...swiperOpts,
  };
  useEffect(() => {
    if (swiperRef.current !== null) {
      setSwiper(swiperRef.current.swiper);
    }

    return () => null;
  }, [ swiperRef ]);

  const next = () => {
    swiper?.slideNext();
  };
  const prev = () => {
    swiper?.slidePrev();
  };
  const container = isContainer ? classes.container : '';
  const makeForDarkTheme = (classes) => isDark ? classes : '';
  const yellowColor = isYellow ? classes.colorYellow : '';
  return (
    <Box sx={{ width: '100%' }}>
      <Container>
        <Box className={classes.main}>
          <Box className={classes.titleWrap}>
            <Box component={'p'}
              className={`${classes.text}
             ${makeForDarkTheme(classes.colorWhite)} ${yellowColor} ${myClassTitle}`}
              sx={{ ...stylesForMobile }}
            >
              {text}
            </Box>
            {subTitle &&
            <p className={classes.subTitle}>
              {subTitle}
            </p>
            }
          </Box>
          {
            showBySize
            &&
            <Box className={`${classes.buttons} ${myClassButtons}`}>
              <IconButton
                className={`${classes.btn} ${makeForDarkTheme(classes.btnWhite)}`}
                onClick={prev}
              >
                <ArrowBackIcon sx={{ color: 'var(--root-color-white)' }} />
              </IconButton>
              <IconButton
                className={`${classes.btn} ${makeForDarkTheme(classes.btnWhite)}`}
                onClick={next}
              >
                <ArrowForwardIcon sx={{ color: 'var(--root-color-white)' }} />
              </IconButton>
            </Box>
          }
        </Box>
      </Container>
      <Box className={`${classes.cont} ${myClass} ${container}`}>
        <Swiper className={`${classes.swiper} swiper-container `} {...options} ref={swiperRef} >
          {React.Children?.map(children, (child, index) => (
            <SwiperSlide key={index}>{child}</SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default CustomInfoBlock;
