import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'footer';

const ENDPOINTS = { footer: '/api/v1/footer/', socialMedia: '/api/v1/social_media/' };

export const getFooter = createAsyncThunk(
  `${name}/getFooter`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.footer);
    return response.data.footer;
  },
);

export const getSocialMedia = createAsyncThunk(
  `${name}/getSocialMedia`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.socialMedia);
    return response.data.results;
  },
);

const initialState = {
  footerStatus: REQUEST_STATUSES.NOT_REQUESTED,
  footer: [],
  footerError: null,
  socialMediaStatus: REQUEST_STATUSES.NOT_REQUESTED,
  socialMedia: [],
  socialMediaError: null
};

const footerSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getFooter, {
      status: 'footerStatus',
      data: 'footer',
      error: 'footerError',
      options: { concat: false },
    });
    addQueryCases(builder, getSocialMedia, {
      status: 'socialMediaStatus',
      data: 'socialMedia',
      error: 'socialMediaError',
      options: { concat: false }
    });
  },
});

export default footerSlice;

export const detailFooter = (state) => state[ name ];
export const detailSocialMedia = (state) => state[ name ];
