import React from 'react';
import ReactDOM from 'react-dom/client';
import AOS from 'aos';

// absolute imports
import 'mapbox-gl/dist/mapbox-gl.css';
import 'aos/dist/aos.css';

import { BrowserRouter } from 'react-router-dom';
import App from './App';

import './i18n';


AOS.init({
  once: true,
  mirror: false,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
