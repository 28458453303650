import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'advantages';

const ENDPOINTS = { advantages: '/api/v1/advantages/', teachers: '/api/v1/teachers/' };

export const getAdvantagesUs = createAsyncThunk(
  `${name}/getAdvantages`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.advantages}`);
    return response.data;
  },
);
export const getTeachers = createAsyncThunk(
  `${name}/getTeachers`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.teachers}`);
    return response.data;
  },
);

const initialState = {
  advantagesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  advantagesData: {},
  advantagesDataError: null,
  teachersStatus: REQUEST_STATUSES.NOT_REQUESTED,
  teachersData: [],
  teachersDataError: null,
};

const advantagesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getAdvantagesUs, {
      status: 'advantagesStatus',
      data: 'advantagesData',
      error: 'advantagesDataError',
      options: { concat: false },
    });
    addQueryCases(builder, getTeachers, {
      status: 'teachersStatus',
      data: 'teachersData',
      error: 'teachersDataError',
      options: { concat: false },
    });
  },
});

export default advantagesSlice;

export const advantagesSelect = (state) => state[ name ];
