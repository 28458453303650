import React, { useEffect } from 'react';
// importing  Routes ;
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route, useLocation } from 'react-router-dom';


// Helmet Provider;

import { HelmetProvider } from 'react-helmet-async';

// importing Redux components
import { Provider } from 'react-redux';
import { store } from 'redux-api/store';

// Hotjar
import Hotjar from '@hotjar/browser';

//// import components
import {
  Home,
  DetailCourse,
  VacancyResume,
  Vacancies,
  AboutUs,
  FAQ,
  Advantages,
  MapSite,
  News,
  NewsDetails,
  AllCourse,
  InfoAboutDevs,
  NotFound,
  Privacy,
  CareerCenter,
  SignIn,
  Direction,
  Students,
  Student,
  GeeksJunior,
  AfterCoursesPage,
  EditStudent
} from 'pages/index.js';
import { Layout } from 'components/Base/index.js';
import Loader from 'components/Loader/Loader.js';
import { ToastContainer } from 'react-toastify';

/// importing MUI components

// importing react-toastigy styles
import 'react-toastify/dist/ReactToastify.css';
import 'mainStyle/toast.css';

// import theme from 'theme';
import theme from './mainStyle/theme.js';
import { PATHS } from './common/constants.js';

// importing scss
import './mainStyle/global.scss';


const siteID = 3671181;
const hotjarVersion = 6;

Hotjar.init(siteID, hotjarVersion);

const App = () => {
  const location = useLocation();

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.pathname === PATHS.privacy) {
      document.getElementById('root').style.background = 'none';
    } else {
      document.getElementById('root').style.background = '';
    }
    scrollTop();
    return () => null;
  }, [ location ]);

  const helmetContext = {};
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <HelmetProvider context={helmetContext}>
            <CssBaseline />
            <ToastContainer position='top-right'/>
            <Routes>
              <Route loader={<Loader/>} element={<Layout isMap/>}>
                <Route index path={PATHS.main} element={<Home />} />
                <Route path={PATHS.vacancies} element={<Vacancies />} />
                <Route path={PATHS.vacancyResume} element={<VacancyResume />} />
                <Route path={PATHS.detailCourse} element={<DetailCourse />} />
                <Route path={PATHS.detailTechnology} element={<InfoAboutDevs />} />
                <Route path={PATHS.mapSite} element={<MapSite />}/>
                <Route path={PATHS.news} element={<News />}/>
                <Route path={PATHS.newsDetails} element={<NewsDetails />}/>
                <Route path={PATHS.afterCourses} element={<AfterCoursesPage/>}/>
                {/* Geeks junior */}
                <Route path={PATHS.geeksJunior} element={<GeeksJunior />} />
              </Route>
              <Route loader={<Loader/>} element={<Layout/>}>
                <Route path={PATHS.allcourses} element={<AllCourse />} />
                <Route path={PATHS.aboutUs} element={<AboutUs />} />
                <Route path={PATHS.FAQ} element={<FAQ />} />
                <Route path={PATHS.advantages} element={<Advantages />} />
                {/* Geeks PRO */}
                <Route path={PATHS.careerCenter} element={<CareerCenter />} />
                <Route path={PATHS.signIn} element={<SignIn />} />
                <Route path={PATHS.direction} element={<Direction />} />
                <Route path={PATHS.students} element={<Students />} />
                <Route path={PATHS.student} element={<Student />} />
                <Route path={PATHS.editStudent} element={<EditStudent />} />
              </Route>
              <Route loader={<Loader/>}>
                <Route path={PATHS.privacy} element={<Privacy />} />
              </Route>
              <Route path='*' element={<NotFound />} />
            </Routes>
          </HelmetProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
};

export default App;
