import React from 'react';
import { Box } from '@mui/material';
import classes from './Loader.module.scss';


const Loader = () => {
  return (
    <Box className={classes.loaderBlock}>
      <Box className={classes.load}>&nbsp;</Box>
    </Box>
  );
};

export default Loader;
