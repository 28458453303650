import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'students';

const ENDPOINTS = { students: (slug) => `/api/v1/career_center/directions/${slug}/` };

export const getStudents = createAsyncThunk(
  `${name}/getStudents`,
  async(slug, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.students(slug)}`);
    return response.data;
  }
);

const initialState = {
  [ name + 'Status' ]: REQUEST_STATUSES.NOT_REQUESTED,
  [ name + 'Data' ]: {},
  [ name + 'Error' ]: null
};

const studentsSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetStudents: (state) => {
      state[ name + 'Data' ] = {};
    }
  },
  extraReducers(builder) {
    addQueryCases(builder, getStudents, {
      status: [ name + 'Status' ],
      data: [ name + 'Data' ],
      error: [ name + 'Error' ],
      options: { concat: false }
    });
  }
});

export default studentsSlice;

export const studentsSelect = state => state?.[ name ];

export const { resetStudents } = studentsSlice.actions;
