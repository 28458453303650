import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'aboutUs';

const ENDPOINTS = { aboutUs: '/api/v1/about_us/' };

export const getAboutUs = createAsyncThunk(
  `${name}/getAboutUs`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.aboutUs}`);
    return { results: response.data };
  },
);

const initialState = {
  aboutUsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  aboutUsData: {},
  aboutUsDataError: null,
};

const aboutUsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getAboutUs, {
      status: 'aboutUsStatus',
      data: 'aboutUsData',
      error: 'aboutUsDataError',
    });
  },
});

export default aboutUsSlice;

export const aboutUsSelect = (state) => state[ name ];
