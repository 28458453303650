import React, { useEffect, useState } from 'react';
import { Box, Popover, Button, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { useChangeFromMedia } from 'common/helpers';
import { PATHS, externalRecourse } from 'common/constants';
import { useTranslation } from 'react-i18next';
import classes from './burger.module.scss';


const popoverStyleForMobile = {
  '& .MuiPopover-paper': {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    background: '#2C2E35',
    boxShadow: 'none',
    padding: '20px 38px',
    top: '50px !important',
    right: 'initial !important',
  },
};


const Burger = ({ show }) => {
  return (
    <Box className={classes.bgParent}>
      <Box className={show ? classes.bgItemActive : classes.bgItem}/>
      <Box className={show ? classes.bgItemActive : classes.bgItem}/>
      <Box className={show ? classes.bgItemActive : classes.bgItem}/>
    </Box>
  );
};


const BurgerMenu = () => {
  const location = useLocation();
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ show, setShow ] = useState(false);
  const { t } = useTranslation();

  const headerPaths = [
    { name: t('Courses'), url: PATHS.allcourses, target: false },
    { name: t('AboutUs'), url: PATHS.aboutUs, target: false },
    { name: t('Advantages'), url: PATHS.advantages, target: false },
    { name: t('AfterCourses'), url: PATHS.afterCourses, target: false },
    { name: t('News'), url: PATHS.news, target: false },
    { name: 'Geeks Junior', url: PATHS.geeksJunior, target: false },
    { name: 'Geeks PRO', url: PATHS.careerCenter, target: false },
    { name: 'GeekStudio', url: externalRecourse.geekstudio, target: true },
  ];

  useEffect(() => {
    setShow(false);
    setAnchorEl(prev => null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ location ]);

  const handleOpenAnchor = (event) => {
    setShow(prev => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShow(prev => !prev);
    setAnchorEl(prev => null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const popOverStyle = useChangeFromMedia({}, popoverStyleForMobile, {}, popoverStyleForMobile, {
    '& .MuiPopover-paper': {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      boxShadow: 'none',
      padding: '12px 80px',
      top: '64px !important',
      right: 0,
      left: '0 !important',
      maxWidth: '100%',
      textAlign: 'right',
      borderRadius: '0'
    },
  });

  return (
    <Box>
      <Button aria-describedby={id} onClick={handleOpenAnchor} sx={{ background: 'transperent' }}>
        <Burger show={show}/>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={popOverStyle}
      >
        {headerPaths.map((item, i) =>
          <NavLink
            className={({ isActive }) => isActive ? classes.activeLink : classes.link}
            to={item.url}
            key={i}
            target={item.target ? '_blank' : ''}
          >
            <Typography className={classes.name}>
              {item.name}
            </Typography>
          </NavLink>
        )}
      </Popover>
    </Box>
  );
};

export default BurgerMenu;
