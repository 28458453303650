import { createTheme } from '@mui/material';


export const theme = createTheme({
  palette: { primary: { main: '#2C2E35' } },
  breakpoints: {
    values: {
      xs: 674, // до 398px
      sm: 850, // от 674px до 674px
      lg: 1485, // от 1200px до 1440px
      mn: 340
    },
  },
  typography: { fontFamily: [ 'Montserrat' ].join(';') },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        fontFamily: 'Montserrat',
      `,
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: '0 !important',
          maxWidth: '1440px !important',
          [ theme.breakpoints.down('lg') ]: { padding: '0 80px !important' },
          [ theme.breakpoints.down('sm') ]: { padding: '0 80px !important' },
          [ theme.breakpoints.down('xs') ]: { padding: '0 16px !important' },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: 0,
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
            backgroundColor: '',
            color: '#ffff',
            '&:hover': { backgroundColor: '#0000' },
          }
          ),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
            color: '#014782',

            '&:hover': { color: '#0286F2' },
          }),
        }),
      },
    },
    MuiList: { styleOverrides: { root: ({ ownerState }) => ({ paddingTop: 0, paddingBottom: 0 }) } }
  },

});


theme.typography.h1 = {
  ...theme.typography.h1,
  fontWeight: '800',
  fontSize: 18,
  color: '#000000',
  lineHeight: '20px',
  [ theme.breakpoints.up ]: {
    fontSize: 28,
    lineHeight: '35px',
  },
};

theme.typography.miniTitleBold = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  color: '#FFF',
  lineHeight: '140%',
  [ theme.breakpoints.down('xs') ]: { fontSize: '16px' },
  [ theme.breakpoints.down('lg') ]: { fontSize: '22px' },
  [ theme.breakpoints.down('mn') ]: { fontSize: '14px' },
};

theme.typography.miniTitle = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '24px',
  color: '#FFF',
  lineHeight: '140%',
  [ theme.breakpoints.down('xs') ]: { fontSize: '16px' },
  [ theme.breakpoints.down('lg') ]: { fontSize: '22px' },
  [ theme.breakpoints.down('mn') ]: { fontSize: '12px' },
};

theme.typography.h6 = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: '#2C2E35',
  lineHeight: '140%',
  [ theme.breakpoints.down('xs') ]: { fontSize: '14px', lineHeight: '100%' },
  [ theme.breakpoints.down('mn') ]: { fontSize: '12px' },
};

theme.typography.miniTitleMedium = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '24px',
  color: '#2C2E35',
  lineHeight: '28px',
  [ theme.breakpoints.down('xs') ]: { fontSize: '20px' },
  [ theme.breakpoints.down('mn') ]: { fontSize: '16px' },
};

theme.typography.imgTitle = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '24px',
  color: '#2C2E35',
  lineHeight: '35px',
  [ theme.breakpoints.down('sm') ]: {
    fontSize: '22px',
    lineHeight: '100%',
  },
  [ theme.breakpoints.down('mn') ]: { fontSize: '16px' },
};

theme.typography.imgSubtitle = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  color: '#2C2E35',
  lineHeight: '140%',
  whiteSpace: 'pre-wrap',
  [ theme.breakpoints.down('xs') ]: {
    fontSize: '11px',
    lineHeight: '120%',
  },
  [ theme.breakpoints.down('lg') ]: { fontSize: '11px' },
  [ theme.breakpoints.down('mn') ]: { fontSize: '10px' },
};

theme.typography.titleGroteskDemi = {
  fontFamily: 'Reforma Grotesk',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '60px',
  color: '#FFF',
  lineHeight: '110%',
  textTransform: 'uppercase',
  textAlign: 'center',
  [ theme.breakpoints.down('xs') ]: { fontSize: '40px', textAlign: 'left' },
  [ theme.breakpoints.down('mn') ]: { fontSize: '26px' },
};


export default theme;
