import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'technology';

const ENDPOINTS = { technology: '/api/v1/technology/' };

export const getTechnologyBySlug = createAsyncThunk(
  `${name}/getTechnologyBySlug`,
  async (slug, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.technology}${slug}/`);

    return response.data;
  },
);

export const getTechnologyList = createAsyncThunk(
  `${name}/getTechnologyList`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.technology}`);

    return response.data;
  },
);

const initialState = {
  technology: {},
  technologyStatus: REQUEST_STATUSES.NOT_REQUESTED,
  technologyError: null,
  technologyList: [],
  technologyListError: null,
  technologyListStatus: REQUEST_STATUSES.NOT_REQUESTED
};

const technologySlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getTechnologyBySlug, {
      status: 'technologyStatus',
      data: 'technology',
      error: 'technologyError',
      options: { concat: false },
    });
    addQueryCases(builder, getTechnologyList, {
      status: 'technologyListStatus',
      data: 'technologyList',
      error: 'technologyListError',
      options: { concat: false },
    });
  },
});

export default technologySlice;

export const detailTechnologySelect = (state) => state[ name ];
