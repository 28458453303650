import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'graduates';

const ENDPOINTS = { graduates: '/api/v1/work_graduates/' };


export const getGraduates = createAsyncThunk(
  `${name}/getGraduates`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.graduates);

    return response.data;
  },
);

const initialState = {
  graduatesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  graduates: [],
  graduatesError: null,
};

const GraduatesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getGraduates, {
      status: 'graduatesStatus',
      data: 'graduates',
      error: 'graduatesError',
      options: { concat: true },
    });
  },
});

export default GraduatesSlice;

export const GraduatesSelect = (state) => state[ name ];
