import { Box, List, ListItem } from '@mui/material';
import { ReactComponent as IconArrow } from 'assets/icons/chevron-down.svg';
import { PATHS } from 'common/constants';
import { useOutsideClick } from 'common/helpers';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './AdvantageMenu.module.scss';


const AdvantageMenu = () => {
  const { t } = useTranslation();
  const [ open, setOpen ] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dropRef = useRef(null);

  const handleNavigate = (path) => {
    if (open) {
      navigate(path);
      setOpen(false);
    } else {
      setOpen((prev) => !prev);
    }
  };

  useOutsideClick(dropRef, () => setOpen(false), open);

  return (
    <Box className={classes.links} ref={dropRef}>
      <span
        className={classes.link}
        onClick={() => handleNavigate(PATHS.aboutUs)}
      >
        <p
          className={`
            ${classes.linkWrap}
            ${pathname === PATHS.aboutUs ? classes.activeLink : ''}
          `}
          title={t('AboutUs')}
        >
          {t('AboutUs')}
        </p>
        <IconArrow className={open ? classes.arrowReverse : ''} />
      </span>
      {open && (
        <List className={classes.linkOptions}>
          <ListItem
            className={`
              ${classes.secondLink}
              ${classes.link}
            `}
            onClick={() => handleNavigate(PATHS.advantages)}
          >
            <p
              className={`
                ${classes.linkWrap}
                ${pathname === PATHS.advantages ? classes.activeLink : ''}
              `}
              title={t('Advantages')}
            >
              {t('Advantages')}
            </p>
          </ListItem>
          <ListItem
            className={`
              ${classes.secondLink}
              ${classes.link}
            `}
          >
            <p
              className={`
                ${classes.linkWrap}
                ${pathname === PATHS.afterCourses ? classes.activeLink : ''}
              `}
              onClick={() => handleNavigate(PATHS.afterCourses)}
              title={t('AfterCourses')}
            >
              {t('AfterCourses')}
            </p>
          </ListItem>
        </List>
      )}
    </Box>
  );
};

export default AdvantageMenu;
