import cls from './MediumTittle.module.scss';


const types = {
  yellow: 'yellow',
  white: 'white',
};
export const MediumTittle = ({ myClass = '', children, type, isBold, aosData = {} }) => {
  const bold = isBold ? cls.bold : '';
  const typeOFColor = type === types.white ? cls.white : cls.yellow;
  return <h1 className={`${cls.title} ${typeOFColor} ${bold} ${myClass}`} {...aosData}>{children}</h1>;
};
