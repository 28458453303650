import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'geeks';

const ENDPOINTS = { geeksOnline: 'api/v1/geeks_online/' };

export const getGeeksOnline = createAsyncThunk(
  `${name}/geeksOnline`,
  async (_, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.geeksOnline}`);
    return response.data.data;
  }
);

const initialState = {
  geeksOnlineStatus: REQUEST_STATUSES.NOT_REQUESTED,
  geeksOnline: {},
  geeksOnlineDataError: null,
};
const geeksOnlineSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getGeeksOnline, {
      status: 'geeksOnlineStatus',
      data: 'geeksOnline',
      error: 'geeksOnlineDataError',
      options: { concat: false }
    });
  }
});

export default geeksOnlineSlice;

export const geeksOnlineSelect = state => state?.[ name ];
