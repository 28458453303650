import { Box, Typography } from '@mui/material';
import classes from './BlockMaskImage.module.scss';


const BlockMaskImage = ({ name, info, image, isDark, image_alt }) => {
  const darkTheme = isDark ? classes.whiteColor : '';
  const FN = name.split(' ');
  return (
    <Box className={classes.main}>
      <Box
        className={classes.imgWrapper}
      >
        <img src={image} alt={image_alt} />
      </Box>
      <Box
        className={classes.info}
      >
        <Typography component='p' className={darkTheme} variant='imgTitle'>
          {FN[ 0 ]}
          <br />
          {FN[ 1 ]}
        </Typography>
        <Typography className={`${classes.imgSubtitle} ${darkTheme}`} variant='imgSubtitle'>
          {info}
        </Typography>
      </Box>
    </Box>
  );
};

export default BlockMaskImage;
