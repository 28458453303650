import { Box, Typography } from '@mui/material';
import { declOfYearsAge } from 'common/helpers';
import classes from './GraduateBlock.module.scss';


const GraduateBlock = ({ image, age, name, position, text, image_alt }) => {

  const ageDecl = declOfYearsAge(age);

  return (
    <Box className={classes.block}>
      <Box className={classes.mainInfo}>
        <Box className={classes.imgWrapper}>
          <Box className={classes.img}>
            <img src={image} alt={image_alt} />
          </Box>
          <Box className={classes.imgSquare}/>
        </Box>
        <Box className={classes.titleWrapper}>
          <Box component='span' className={classes.nameInfo}>
            {name} <br/> {age} {ageDecl}
          </Box>
          <Box component='p' className={classes.position}>
            {position}
          </Box>
        </Box>
      </Box>
      <Typography component='p' variant='imgSubtitle' className={classes.subTitle}>
        {text}
      </Typography>
    </Box>
  );
};

export default GraduateBlock;
