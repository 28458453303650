import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'news';

const ENDPOINTS = {
  news: '/api/v1/news',
  recommended: '/api/v1/recomendation/news/'
};

export const getNewsList = createAsyncThunk(
  `${name}/getNewsList`,
  async (page, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.news}?page=${page}`);
    return response.data;
  }
);

export const getNews = createAsyncThunk(
  `${name}/getNews`,
  async (id, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.news}/${id}`);
    return response.data;
  }
);

export const getNewsRecommended = createAsyncThunk(
  `${name}/getNewsRecommended`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.recommended);
    return response.data.results;
  }
);

const initialState = {
  newsListStatus: REQUEST_STATUSES.NOT_REQUESTED,
  newsList: [],
  newsListError: null,

  newsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  news: {},
  newsError: null,

  newsRecommendedStatus: REQUEST_STATUSES.NOT_REQUESTED,
  newsRecommended: [],
  newsRecommendedError: null
};

const newsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getNewsList, {
      status: 'newsListStatus',
      data: 'newsList',
      error: 'newsListError',
      options: { concat: false },
    });
    addQueryCases(builder, getNews, {
      status: 'newsStatus',
      data: 'news',
      error: 'newsError',
      options: { concat: false },
    });
    addQueryCases(builder, getNewsRecommended, {
      status: 'newsRecommendedStatus',
      data: 'newsRecommended',
      error: 'newsRecommendedError',
      options: { concat: false },
    });
  }
});

export default newsSlice;

export const selectNews = (state) => state[ name ];
