import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// translation
import ru from 'translation/ru.json';
import ky from 'translation/ky.json';


const resources = {
  ru: { translation: ru },
  ky: { translation: ky },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('lang') ?? 'ru',
    fallbackLng: 'ru',
    interpolation: { escapeValue: false }
  });
