import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'vacancy';

const ENDPOINTS = { vacancy: '/api/v1/vacancy/', send_resume: '/api/v1/send_resume/' };

export const getVacancyCards = createAsyncThunk(
  `${name}/getVacancyCards`,
  async (_, { extra: api }) => {
    try {
      const { data } = await api.get(ENDPOINTS.vacancy);
      return data;
    } catch (e) {
      return e;
    }
  },
);
export const getOneVacancyCard = createAsyncThunk(
  `${name}/getOneVacancyCard`,
  async (id, { extra: api }) => {
    try {
      const { data } = await api.get(ENDPOINTS.vacancy + id + '/');
      return data;
    } catch (e) {
      return e;
    }
  },
);
export const sendResume = createAsyncThunk(
  `${name}/sendResume`,
  async (formData,) => {
    try {
      const { data } = await axios.post(BASE_URL + ENDPOINTS.send_resume,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } });
      return data;
    } catch (e) {
      return e;
    }
  },
);

const initialState = {
  vacancyCardStatus: REQUEST_STATUSES.NOT_REQUESTED,
  vacancyCards: [],
  vacancyOneCardStatus: REQUEST_STATUSES.NOT_REQUESTED,
  vacancyOneCard: {},
  open: false,
  modalAlert: false,
  sendResumeStatus: REQUEST_STATUSES.NOT_REQUESTED,
  sendResumeData: null,
  sendResumeError: null
};

export const VacancySlice = createSlice({
  name,
  initialState,
  reducers: {
    OpenIn: (state) => {
      state.open = true;
    },
    OpenClose: (state) => {
      state.open = false;
    },
    modalAlertOpen: (state) => {
      state.modalAlert = true;
    },
    modalAlertClose: (state) => {
      state.modalAlert = false;
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, getVacancyCards, {
      status: 'vacancyCardStatus',
      data: 'vacancyCards',
      error: 'vacancyCardsError',
    });
    addQueryCases(builder, getOneVacancyCard, {
      status: 'vacancyOneCardStatus',
      data: 'vacancyOneCard',
      error: 'vacancyOneCardError',
      options: { concat: false },
    });
    addQueryCases(builder, sendResume, {
      status: 'sendResumeStatus',
      data: 'sendResumeData',
      error: 'sendResumeError',
      options: { concat: false },
    });
  },
});


export default VacancySlice;
export const { OpenIn, OpenClose, modalAlertOpen, modalAlertClose } = VacancySlice.actions;
export const vacancySelect = state => state[ name ];
