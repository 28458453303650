import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'reviews';

const ENDPOINTS = { reviews: '/api/v1/reviews/?course__slug=', reviewsAll: '/api/v1/reviews/' };

export const getReviewsBySlug = createAsyncThunk(
  `${name}/getReviewsBySlug`,
  async (slug, { extra: api }) => {
    const response = await api.get(`${ENDPOINTS.reviews}${slug}`);

    return response.data;
  },
);

export const getReviewsAll = createAsyncThunk(
  `${name}/getReviewsAll`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.reviewsAll);

    return response.data;
  },
);

const initialState = {
  reviewsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  reviews: [],
  reviewsError: null,
};

const reviewsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getReviewsBySlug, {
      status: 'reviewsStatus',
      data: 'reviews',
      error: 'reviewsError',
      options: { concat: false },
    });
    addQueryCases(builder, getReviewsAll, {
      status: 'reviewsStatus',
      data: 'reviews',
      error: 'reviewsError',
      options: { concat: false },
    });
  },
});

export default reviewsSlice;

export const reviewsSelect = (state) => state[ name ];
