import React from 'react';
import { Box, Container, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from 'components/UI';
import { PATHS, BITRIX } from 'common/constants';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/icons/Logo.svg';
import Navigation from './HeaderComponents/Navigation/Navigation';
import { LanguageMenu } from './HeaderComponents/LanguageMenu';
import { BurgerMenu } from './HeaderComponents/BurgerMenu';
import classes from './header.module.scss';


const Header = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const headerPaths = [
    { name: t('News'), url: PATHS.news, target: false },
    { name: 'Geeks Junior', url: PATHS.geeksJunior, target: false },
    { name: 'Geeks PRO', url: PATHS.careerCenter, target: false },
  ];

  return (
    <>
      <header className={classes.header}>
        <Container className={classes.headerContainer}>

          {/* Desktop header */}
          <Toolbar className={classes.toolBar}>
            <Box className={classes.logo} onClick={() => navigate('/')} component={'img'} src={Logo} alt='logo icon'/>
            <Navigation headerPaths={headerPaths}/>
            <Box className={classes.flexList} >
              <CustomButton MyClass={classes.headerBtn} text={t('SignUp')}
                path={BITRIX.course} isRounded={true} isArrow={true} />
            </Box>
          </Toolbar>

          {/* Mobile header */}
          <Toolbar className={classes.mobileToolbar}>
            <Box className={classes.logo} onClick={() => navigate('/')} component={'img'} src={Logo} alt='logo icon'/>
            <Box className={classes.flexList}>
              <LanguageMenu headerPaths={headerPaths} />
              <BurgerMenu/>
            </Box>
          </Toolbar>

        </Container>
      </header>
    </>
  );
};

export default Header;
