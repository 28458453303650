import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_STATUSES } from 'common/constants';
import { addQueryCases } from 'common/helpers';


const name = 'home';

const ENDPOINTS = {
  home: '/api/v1/main_page/',
  center_page: '/api/v1/center_page/',
  student_review: '/api/v1/student_review/',
};

export const getHome = createAsyncThunk(
  `${name}/getHome`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.home);
    return response.data;
  },
);

export const getCenterPage = createAsyncThunk(
  `${name}/getCenterPage`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.center_page);
    return response.data.results?.[ 0 ];
  },
);

export const getOurGraduates = createAsyncThunk(
  `${name}/getOurGraduates`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.student_review);
    return response.data;
  },
);

const initialState = {
  homeStatus: REQUEST_STATUSES.NOT_REQUESTED,
  home: [],
  homeError: null,
  centerPageStatus: REQUEST_STATUSES.NOT_REQUESTED,
  centerPage: {},
  centerPageError: null,
  studentGraduatesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  studentGraduates: [],
  studentGraduatesError: null,
};

const homeSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getHome, {
      status: 'homeStatus',
      data: 'home',
      error: 'homeError',
      options: { concat: true },
    });
    addQueryCases(builder, getCenterPage, {
      status: 'centerPageStatus',
      data: 'centerPage',
      error: 'centerPageError',
      options: { concat: false },
    });
    addQueryCases(builder, getOurGraduates, {
      status: 'studentGraduatesStatus',
      data: 'studentGraduates',
      error: 'studentGraduatesError',
    });
  },
});

export default homeSlice;

export const homeSelect = (state) => state[ name ];
